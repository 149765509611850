<template>
  <footer class="footer">
    <div class="container ">
      <div class="row justify-content-md-center pt-2 ">
        <div
          class="col-md-4 col-12 d-flex d-inline-block justify-content-md-start justify-content-center"
        >
          <img :src="logo" height="60" alt="Logo" />
        </div>
        <div class="col-md-4 col-6 d-flex justify-content-md-center">
          <ul class="list-unstyled d-inline-block">
            <small>
              <li>
                <a
                  href="mailto:fellpunzel@web.de"
                  target="_blank"
                  style="color: white;"
                  ><b-icon icon="envelope" /> fellpunzel@web.de</a
                >
              </li>
              <li>
                <a
                  href="tel:+491784884448"
                  target="_blank"
                  style="color: white;"
                  ><b-icon icon="telephone" /> 01784884448</a
                >
              </li>
              <li>
                <a
                  href="https://www.instagram.com/fellpunzel_hundesalon/"
                  target="_blank"
                  style="color: white;"
                  ><b-icon icon="instagram" /> Fellpunzel@Instagram</a
                >
              </li>
              <li>
                <a
                  href="https://www.facebook.com/Fellpunzel-171758497739509/?ref=py_c"
                  target="_blank"
                  style="color: white;"
                  ><b-icon icon="facebook" /> Fellpunzel@Facebook</a
                >
              </li>
            </small>
          </ul>
        </div>
        <div class="col-md-4 col-6 d-flex d-inline-block justify-content-end">
          <a
            href="https://goo.gl/maps/93DdPhnqHG1kL86q8"
            target="_blank"
            style="color: white;"
            class="d-flex d-inline-block"
          >
            <ul class="list-unstyled d-inline-block">
              <small>
                <li>Fellpunzel</li>
                <li>Hänflingstr. 7-9</li>
                <li>45472 Mülheim an der Ruhr</li>
              </small>
            </ul>
          </a>
        </div>
      </div>
      <div
        class="text-center d-flex justify-content-center pb-3"
        style="color: white;"
      >
        <small>
          Copyright © Fellpunzel GmbH
        </small>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "SiteFooter",
  data: () => {
    return {
      logo: require("../../assets/figures/Logos/Fellpunzel_Querformat_mitUnterzeile_1c-weiß.svg")
    };
  }
};
</script>
