import { initializeApp } from 'firebase/app';
import { getFirestore, Timestamp, FieldValue } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC7C_gZx-2JDigBZ7gMI2YexwzKDVTR_yU",
  authDomain: "fellpunzel.firebaseapp.com",
  projectId: "fellpunzel",
  storageBucket: "fellpunzel.appspot.com",
  messagingSenderId: "326262866582",
  appId: "1:326262866582:web:2acdc45ed3cca1dc9a38b7",
  measurementId: "G-M96RJLZTMD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
getAnalytics(app); // Nur Initialisierung, keine Zuweisung

export { Timestamp, FieldValue };
export default db;
