<template>
  <!-- Die Klassen hier werden benötigt damit der Footer auch tatsächlich immer am Boden des Screens abgebildet wird -->
  <div id="app" class="d-flex flex-column min-vh-100">
    <div
      class="navbar-div sticky-top navbar-shadow"
      style="background-color: white;"
    >
      <div class="justify-content-center d-none d-lg-block text-center ">
        <b-navbar-brand to="/">
          <img :src="logo" height="120" alt="Logo" />
        </b-navbar-brand>
      </div>
      <Navbar />
    </div>

    <!-- flex-grow-1 sorgt dafür das der komplette freie Space genutzt wird falls der Content nicht reicht um den footer unten zu positionieren-->
    <router-view class="container flex-grow-1" />

    <site-footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
// import Navbar2 from "@/components/Navbar2.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar: Navbar,
    // Navbar2,
    'site-footer': Footer
  },
  data: () => {
    return {
      logo: require("../assets/figures/Logos/Fellpunzel_Querformat_mitUnterzeile_1c-gold.svg")
    };
  }
};
</script>

<style>
@import "../assets/css/main.css";
</style>
