<template>
  <div class="container">
    <div class="text-center pt-3">
      <h1 class="fellpunzel-gold">
        Willkommen bei Fellpunzel - Ihrem Hundesalon für ein glänzendes Fell und
        glückliche Pfoten!
      </h1>
    </div>

    <Carousel class="pt-1" />
    <div class="text-center pt-3">
      <!-- <h1>
        Willkommen bei Fellpunzel - Ihrem Hundesalon für ein glänzendes Fell und
        glückliche Pfoten!
      </h1> -->
      <br />
      <p>
        Ich biete eine breite Palette von Dienstleistungen an, die genau auf die
        Bedürfnisse Ihres Hundes abgestimmt sind. Egal, ob es um ein einfaches
        Bad, eine professionelle Schur, das Schneiden der Krallen oder um eine
        individuelle Fellpflege geht. Denn ihr vierbeiniger Freund ist mehr als
        nur ein Haustier, er ist ein treues Familienmitglied, der beste
        Spielkamerad und ein wertvolles Mitglied Ihres Lebens. Deshalb verdienen
        unsere pelzigen Begleiter nur das Beste, wenn es um ihre Pflege geht.
        Und genau das bieten Ich Ihnen bei Fellpunzel - erstklassige Pflege für
        Ihren geliebten Hund.
      </p>
      <p>
        Vertrauen Sie Fellpunzel Ihren vierbeinigen Gefährten an. - Ich sorge
        dafür, dass er sich verwöhnt und geliebt fühlt. Gönnen Sie Ihrem Hund
        die Pflege, die er verdient, und lassen Sie ihn bei mir im neuen Glanz
        erstrahlen!
      </p>
      <h3 class="fellpunzel-gold pt-4">Einfach Termin anfragen</h3>
      <p>
        Nutzen Sie die Terminanfrage und teilen Sie mir die Wünsche für Ihren Hund mit. 
        Ich melde mich schnellstmöglich mit einem passenden Termin. Ich freue mich auf Ihren Vierbeiner!
      </p>
      <b-button
        variant="outline-fellpunzel"
        to="/Terminbuchen"
        class="mb-4"
      >
        Zur Terminanfrage
      </b-button>
      <p>
        Ihr Hundesalon Fellpunzel, <br />
        Sabrina Wibranek
      </p>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/HomeCarousel.vue";
import db from "../db.js";
import { doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

export default {
  name: "HomePage",
  data() {
    return {
      name: null,
      image: null
    };
  },
  async mounted() {
    try {
      const docRef = doc(db, "test", "bWi9LReIWDr7mVxrd4Jq");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.name = docSnap.data().test;
      }

      const storage = getStorage();
      const imageRef = ref(storage, "benjamin-lehman-qwIeh5M8bF8-unsplash.jpg");
      const url = await getDownloadURL(imageRef);
      this.image = url;
    } catch (error) {
      console.error("Error loading data:", error);
    }
  },
  components: {
    Carousel
  }
};
</script>
